import React, { useState, useEffect } from 'react';

import Unicorn from './ressources/Unicorn.svg'
import Arrow_pubUp from './ressources/Arrow-PubUp.svg'
import Arrow_White from './ressources/Arrow-White.svg'
import PubUp_Screen_1 from './ressources/PubUp_1.png'
import PubUp_Screen_2 from './ressources/PubUp_2.png'
import Tebea from './ressources/Tebea-Frontend.jpg'
import Blome from './ressources/Benno-Frontend.jpg'

import './main.css'


const Main = () => {

    const [slide, setSlide] = useState(1)

    const renderFixedUnicorn = () => {
        if (slide !== 1 && slide !== 9) {
            return <img className="unicorn" src={Unicorn} onClick={() => setSlide(1)} alt="Unicorn" />
        }
    }

    const setRightPubUpSrc = () => {
        if (slide === 3) {
            return PubUp_Screen_1
        } else if (slide === 4) {
            return PubUp_Screen_2
        }
    }

    const renderPubUpContent = () => {
        if (slide === 2) {
            return (
                <div className="content_container" onClick={(e) => {
                    if (e.target.nodeName !== "A") {
                        setSlide(slide + 1)
                    }
                }}>
                    <h1>Pub Up</h1>
                    <p>Pub Up is an App to filter and find Pubs, Bars &amp; Events in Berlin. You can also arrange meetings with friends in a "Stammtisch".<br /><br />What I did:<br /><span>- Logo &amp; Corporate Design<br />- UX &amp; UI Design<br />- App Developemnt<br />- Web App Developement<br /><br /></span></p>
                    <a
                        href="https://www.pub-up.de/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >GO TO WEBSITE</a>
                </div>
            )
        } else if (slide === 3 || slide === 4) {
            return (
                <div className="content_container c-1" onClick={(e) => setSlide(slide + 1)}>
                    <div className="pub_up_screens">
                        <img src={setRightPubUpSrc()} className="screen" alt="Pub Up App Screens" />
                    </div>
                </div>
            )
        }
    }

    const renderComponents = () => {
        if (slide === 1) {
            return (
                <div className="first_container" onClick={() => setSlide(slide + 1)}>
                    <img src={Unicorn} alt="Unicorn Graphic" />
                    <p>
                        <span className="fadeIn1">Hey there, I am Jakob</span><br />
                        <span className="fadeIn2">a UX &amp; UI Designer,</span><br />
                        <span className="fadeIn3">a Web Developer...</span><br /><br />
                        <span className="fadeIn4">and a <span className="letter1">Unicorn</span>.<br /></span>
                        <span className="fadeIn5">Click the Screen<br />to view my portfolio...</span></p>
                </div>
            )
        } else if (slide === 2 || slide === 3 || slide === 4) {
            return (
                <div className="second_container">
                    {renderPubUpContent()}
                    <p className="continue">Click the Screen to continue...<br />Or contact me at <a href="mailto:info@jakob-sawal.de">info@jakob-sawal.de</a></p>
                    <img src={Arrow_pubUp} className="back" onClick={() => setSlide(slide - 1)} alt="Back Arrow" />
                </div>
            )
        } else if (slide === 5) {
            /* TEBEA BOOKING */
            return (
                <div className="third_container">
                    <div className="content_container" onClick={(e) => {
                        if (e.target.nodeName !== "A") {
                            setSlide(slide + 1)
                        }
                    }}>
                        <h1>Tebea Booking</h1>
                        <p>Tebea Booking is one the biggest underground booking agencies in Hamburg.<br /><br />What I did:<br /><span>- UX &amp; UI Design<br />- Wordpress implementation<br /><br /></span></p>
                        <a
                            href="https://tebeabooking.com/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >GO TO WEBSITE</a>
                    </div>
                    <p className="continue">Click the Screen to continue...<br />Or contact me at <a href="mailto:info@jakob-sawal.de">info@jakob-sawal.de</a></p>
                    <img src={Arrow_White} className="back" onClick={() => setSlide(slide - 1)} alt="Back Arrow" />
                </div>

            )
        } else if (slide === 6) {
            /* TEBEA BOOKING */
            return (
                <div className="third_container">
                    <div className="content_container" onClick={(e) => {
                        if (e.target.nodeName !== "A") {
                            setSlide(slide + 1)
                        }
                    }}>
                        <img alt="Tebea Booking Frontend" src={Tebea} className="website-mockups" />
                        <a
                            href="https://tebeabooking.com/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >GO TO WEBSITE</a>
                    </div>
                    <p className="continue">Click the Screen to continue...<br />Or contact me at <a href="mailto:info@jakob-sawal.de">info@jakob-sawal.de</a></p>
                    <img src={Arrow_White} className="back" onClick={() => setSlide(slide - 1)} alt="Back Arrow" />
                </div>

            )
        } else if (slide === 7) {
            /* TEBEA BOOKING */
            return (
                <div className="third_container">
                    <div className="content_container" onClick={(e) => {
                        if (e.target.nodeName !== "A") {
                            setSlide(slide + 1)
                        }
                    }}>
                        <h1>Benno Blome</h1>
                        <p>Benno BLome is a music producer, DJ, A&amp;R in the electronic music and a film maker. Here's his new website.<br /><br />What I did:<br /><span>- UX &amp; UI Design<br />- HTML, CSS, JS, jQuery Development<br /><br /></span></p>
                        <a
                            href="https://bennoblome.de/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >GO TO WEBSITE</a>
                    </div>
                    <p className="continue">Click the Screen to continue...<br />Or contact me at <a href="mailto:info@jakob-sawal.de">info@jakob-sawal.de</a></p>
                    <img src={Arrow_White} className="back" onClick={() => setSlide(slide - 1)} alt="Back Arrow" />
                </div>

            )
        } else if (slide === 8) {
            /* TEBEA BOOKING */
            return (
                <div className="third_container">
                    <div className="content_container" onClick={(e) => {
                        if (e.target.nodeName !== "A") {
                            setSlide(slide + 1)
                        }
                    }}>
                        <img alt="Benno Blome Frontend" src={Blome} className="website-mockups" />
                        <a
                            href="https://bennoblome.de/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >GO TO WEBSITE</a>
                    </div>
                    <p className="continue">Click the Screen to continue...<br />Or contact me at <a href="mailto:info@jakob-sawal.de">info@jakob-sawal.de</a></p>
                    <img src={Arrow_White} className="back" onClick={() => setSlide(slide - 1)} alt="Back Arrow" />
                </div>

            )
        } else if (slide === 9) {
            return (
                <div className="first_container" onClick={(e) => {
                    if (e.target.nodeName !== "A") {
                        setSlide(slide + 1)
                    }
                }}>
                    <img src={Unicorn} alt="Unicorn Graphic" />
                    <p className="fadeIn1" >Book me for your next project<br /><a href="mailto:info@jakob-sawal.de">info@jakob-sawal.de</a><br /><br />Thank you for clicking.<br />Click the Screen to start again.<br /><br /><a href="https://www.jakob-sawal.de/Impressum/Impressum_Datenschutzerklaerung.pdf" target="_blank">Impressum</a></p>
                </div>
            )
        }
    }

    useEffect(() => {
        /* if (slide < 1) {
            setSlide(1)
        } else  */if (slide > 9) {
            setSlide(1)
        }
    }, [slide])

    return (
        <div className="main_container">
            {renderFixedUnicorn()}
            {renderComponents()}
        </div>
    )
}


export default Main;